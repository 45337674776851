import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/SEO';
import Hero from '../components/Hero';
import PartnersPost from '../components/PartnersPost';
import ConversionPanel from '../components/ConversionPanel';

const PartnerTemplate = ({ data, location }) => {
  const { conversionPanel, partner } = data;

  return (
    <Layout>
      <SEO
        title={partner?.seo?.seoTitle || partner?.name}
        description={partner?.seo?.description || partner?.summary?.summary}
        image={partner?.seo?.openGraphImage?.file?.url}
        noIndex={partner?.seo?.noindex}
        noFollow={partner?.seo?.nofollow}
        canonicalUrl={partner?.seo?.canonicalUrl}
      />
      <Hero
        component={{
          showBreadcrumbs: true,
          partner,
        }}
      />
      <PartnersPost partner={partner} location={location} />
      <ConversionPanel component={conversionPanel} />
    </Layout>
  );
};

export default PartnerTemplate;

export const pageQuery = graphql`
  query ($slug: String!) {
    partner: contentfulEntityPartner(slug: { eq: $slug }) {
      ...contentfulEntityPartner
    }
    conversionPanel: contentfulComponentConversionPanel(
      contentful_id: { eq: "wY12AHbTBGsJoxNlCTN8Q" }
    ) {
      ...contentfulComponentConversionPanel
    }
  }
`;
